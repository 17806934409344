import * as React from 'react';
import {cloneDeep, isEqual, random} from 'lodash-es';
import {
  VideoFile as IVideoFile,
  PlayerEventType,
  PlayerStatus,
} from '@accurate-player/accurate-player-core';
import {ProgressivePlayer} from '@accurate-player/accurate-player-progressive';
import {HlsPlayer} from '@accurate-player/accurate-player-hls';
import {
  HotkeyPlugin,
  SmoothTimeUpdatePlugin,
  VttSubtitlePlugin,
} from '@accurate-player/accurate-player-plugins';
import '@accurate-player/accurate-player-controls';

const rootRef = document.createElement('apc-markers-root');

const markerCss = `:host { position: relative; display: block; width: 100%; pointer-events: none; bottom: 2px; }
  :host .point { position: absolute; bottom: 1px; z-index: 1; height: .3rem; border: 1px solid #777; border-radius: 50px; width: 2px; background: #777; }
  :host .inout { position: absolute; bottom: 1px; z-index: 1; height: .3rem; border: 1px solid #FFDE2B; border-radius: 50px; width: 2px; background: #FFDE2B; }`;

type TSize =
  | `${number}${'%' | 'px' | 'rem' | 'em' | 'vw' | 'vh' | 'fr'}`
  | 'unset'
  | `calc(${string})`
  | 'auto';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'apc-controls': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export type TVideoFile = Omit<IVideoFile, 'src'>;

interface IPlayerHotKey {
  hotkey: string;
  callback: (player: ProgressivePlayer | HlsPlayer) => void;
  desc: string;
}

type TPoint = 'pod' | 'in' | 'out';

export interface IPlayerPoint {
  timestamp: number;
  type: TPoint;
}

interface IPlayerControls extends HTMLElement {
  updatePoints(): void;
  destroy(): void;
  unregisterVideoEvents(elem: HTMLVideoElement | null): void;
  init(
    elem: HTMLVideoElement | null,
    player: ProgressivePlayer | HlsPlayer,
    options: any,
  );
}

export interface IPlayerSubtitle {
  enabled: boolean;
  label: string;
  source: string;
  language: string;
}

export interface IPlayerProps {
  /** Set the height of the video tag */
  height?: TSize;
  /** Set an id */
  id?: string;
  /** Set which type of player you need */
  playerType?: 'progressive' | 'hls';
  /** URL to load */
  src: string;
  /** License key for Accurate Player */
  license?: string;
  /** Player options */
  options: TVideoFile;
  /** HLS Player options */
  hlsPlayerQueryString?: string;
  /** List of subtitles */
  subtitles?: IPlayerSubtitle[];
  /** List of custom hotkeys */
  hotkeys?: IPlayerHotKey[];
  /** Marked points on video */
  points?: IPlayerPoint[];
  /** Duration of the clip. Only needed when using points */
  duration?: number;
  /** Callback for player has error */
  onError?(): void;
  /** Callback for when playback had ended */
  onEnded?(): void;
  /** Callback for when player has loaded */
  onLoad?(): void;
}

export interface IPlayerRef {
  play(): void;
  pause(): void;
  playPause(): void;
  getCurrentTime(): number;
  getDuration(): number;
  getStatus(): PlayerStatus | undefined;
  seekTo(timestamp: number): void;
  isPlaying: boolean;
}

/**
 * Wrapper for Accurate Player. Works with Progressive and HLS Players. There are no examples because we need a license key to enable the player to work. Once we get one we will add examples here.
 */
export const Player = React.memo(
  React.forwardRef<IPlayerRef, IPlayerProps>(
    (
      {
        height = 'auto',
        id = `player-${random(1000, 9999)}`,
        playerType = 'progressive',
        src,
        options,
        hlsPlayerQueryString,
        license = '',
        hotkeys,
        subtitles,
        points,
        duration,
        onError,
        onEnded,
        onLoad,
      }: IPlayerProps,
      ref,
    ) => {
      const player = React.useRef<ProgressivePlayer | HlsPlayer>();
      const hotkeyPlugin = React.useRef<HotkeyPlugin>();
      const smoothTimeUpdatePlugin = React.useRef<SmoothTimeUpdatePlugin>();
      const subtitlePlugin = React.useRef<VttSubtitlePlugin>();
      const videoRef = React.useRef<HTMLVideoElement>(null);
      const controlsRef = React.useRef<IPlayerControls>(null);
      const wrapperRef = React.useRef<HTMLDivElement>(null);

      const markersRef = React.useRef<HTMLElement>();
      const markersRootRef = React.useRef<HTMLElement>(rootRef);
      const markersAttached = React.useRef<boolean>(false);

      const subtitlesRef = React.useRef<IPlayerSubtitle[]>();
      const hotkeysRef = React.useRef<IPlayerHotKey[]>();

      const isPlaying = React.useRef<boolean>(false);

      React.useImperativeHandle(ref, () => ({
        getCurrentTime: () => +(player.current?.api?.getCurrentTime() || 0),
        getDuration: () => +(player.current?.api?.getDuration() || 0),
        getStatus: () => player.current?.api.getStatus(),
        isPlaying: isPlaying.current,
        pause: () => player.current?.api.pause(),
        play: () => player.current?.api.play(),
        playPause: () => player.current?.api.togglePlay(),
        seekTo: (time: number) =>
          player.current?.api.seek({format: 3, mode: 2, time}),
      }));

      const generatePoint = (startsAt: number, type: TPoint): HTMLElement => {
        const marker = document.createElement('div');
        marker.setAttribute('class', type !== 'pod' ? 'inout' : 'point');

        const clipDuration = Math.max(
          duration || 0,
          +(player.current?.api?.getDuration() || 0),
        );
        const left = (startsAt / clipDuration || 1) * 100;

        marker.setAttribute('style', `left:${left}%`);

        return marker;
      };

      const pointsChanged = () => {
        setupMarkers();

        markersRootRef.current
          ?.querySelectorAll('.point, .inout')
          .forEach(elem => elem.remove());

        points?.forEach(point => {
          const actualDuration = Math.ceil(
            Math.max(duration || 0, +(player.current?.api?.getDuration() || 0)),
          );

          if (
            point.timestamp > 0 &&
            Math.ceil(point.timestamp) !== actualDuration
          ) {
            markersRootRef.current?.append(
              generatePoint(point.timestamp, point.type),
            );
          }
        });
      };

      const setupMarkers = () => {
        if (!markersAttached.current && controlsRef.current !== null) {
          markersRef.current = document.createElement('apc-markers');
          markersRef.current.attachShadow({mode: 'open'});

          const markerStyles = document.createElement('style');
          markerStyles.textContent = markerCss;

          markersRef.current.shadowRoot?.append(markerStyles);
          markersRef.current.shadowRoot?.append(markersRootRef.current);

          // Deferring until the controls have been setup
          setTimeout(() => {
            const timeline = controlsRef.current?.shadowRoot
              ?.querySelector('apc-control-footer')
              ?.shadowRoot?.querySelector('apc-timeline');

            if (timeline) {
              timeline.shadowRoot?.append(markersRef.current!);
              markersAttached.current = true;
            }
          });
        }
      };

      const cleanup = React.useCallback((removeControls = false) => {
        if (hotkeyPlugin.current) {
          try {
            hotkeyPlugin.current.destroy();
            hotkeyPlugin.current = undefined;
          } catch (e) {}
        }

        if (smoothTimeUpdatePlugin.current) {
          try {
            smoothTimeUpdatePlugin.current.destroy();
            smoothTimeUpdatePlugin.current = undefined;
          } catch (e) {}
        }

        if (subtitlePlugin.current) {
          try {
            subtitlePlugin.current.deleteAllVttSubtitles();
            subtitlePlugin.current.destroy();
            subtitlePlugin.current = undefined;
          } catch (e) {}
        }

        if (controlsRef.current) {
          if (removeControls) {
            try {
              controlsRef.current.destroy();
            } catch (e) {}
          } else {
            try {
              controlsRef.current.unregisterVideoEvents(videoRef.current);
            } catch (e) {}
          }
        }

        if (player.current) {
          try {
            player.current.removeAllListeners();
          } catch (e) {}
        }
      }, []);

      const endedListener = () => {
        if (!player.current?.api.isSeeking) {
          onEnded && onEnded();
        }
      };

      const errorListener = () => onError && onError();

      const loadedListener = () => {
        pointsChanged();
        onLoad && onLoad();
      };

      const setupListeners = () => {
        const playbackStatus = ({
          status,
        }: {
          status: {ended: boolean; paused: boolean};
        }) => {
          if (!status.ended) {
            isPlaying.current = !status.paused;
          }
        };

        if (player.current) {
          try {
            if (onEnded) {
              player.current.removeEventListener(
                PlayerEventType.Ended,
                endedListener,
              );
              player.current.addEventListener(
                PlayerEventType.Ended,
                endedListener,
              );
            }
            if (onError) {
              player.current.removeEventListener(
                PlayerEventType.Error,
                errorListener,
              );
              player.current.addEventListener(
                PlayerEventType.Error,
                errorListener,
              );
            }
            if (onLoad) {
              player.current.removeEventListener(
                PlayerEventType.Loaded,
                loadedListener,
              );
              player.current.addEventListener(
                PlayerEventType.Loaded,
                loadedListener,
              );
            }

            player.current.removeEventListener(
              PlayerEventType.StatusChanged,
              playbackStatus,
            );
            player.current.addEventListener(
              PlayerEventType.StatusChanged,
              playbackStatus,
            );
          } catch (e) {}
        }
      };

      const setupHotkeys = () => {
        if (!player.current) {
          return;
        }

        if (!hotkeyPlugin.current) {
          hotkeyPlugin.current = new HotkeyPlugin(player.current);
        }

        if (isEqual(hotkeysRef.current, hotkeys)) {
          return;
        }

        (hotkeysRef.current || []).forEach(hk =>
          hotkeyPlugin.current?.clearHotkey(hk.hotkey),
        );

        (hotkeys || []).forEach(hk => {
          hotkeyPlugin.current?.setHotkey(
            hk.hotkey,
            () => hk.callback(player.current as ProgressivePlayer | HlsPlayer),
            hk.desc,
          );
        });

        hotkeysRef.current = cloneDeep(hotkeys);
      };

      const setupSubtitles = () => {
        if (!player.current) {
          return;
        }

        if (!subtitlePlugin.current) {
          subtitlePlugin.current = new VttSubtitlePlugin(player.current);
        }

        if (isEqual(subtitlesRef.current, subtitles)) {
          return;
        }

        const enabledSub = subtitlePlugin.current.vttSubtitles.find(
          cc => cc.enabled,
        );

        subtitlePlugin.current.deleteAllVttSubtitles();

        subtitlePlugin.current.createVttSubtitles(
          (subtitles || []).map(cc => ({
            enabled: cc.enabled || enabledSub?.srclang === cc.language,
            kind: 'subtitles',
            label: cc.label,
            language: cc.language,
            src: cc.source,
            srclang: cc.language,
          })),
        );

        subtitlesRef.current = cloneDeep(subtitles);
      };

      const sourceChanged = () => {
        if (
          !videoRef?.current ||
          !controlsRef?.current ||
          !src ||
          !license.length
        ) {
          return;
        }

        cleanup();

        const pType =
          playerType === 'progressive' ? ProgressivePlayer : HlsPlayer;

        let settings;

        if (
          playerType === 'hls' &&
          hlsPlayerQueryString &&
          hlsPlayerQueryString.length > 0
        ) {
          settings = {
            hlsConfig: {
              xhrSetup: (xhr: XMLHttpRequest, url: string) => {
                xhr.open('GET', `${url}?${hlsPlayerQueryString}`, true);
              },
            },
          };
        }

        player.current = new pType(videoRef.current, license, settings);

        player.current.api.loadVideoFile({
          dropFrame: false,
          enabled: true,
          ...options,
          src,
        });

        setupHotkeys();
        setupSubtitles();

        smoothTimeUpdatePlugin.current = new SmoothTimeUpdatePlugin(
          player.current,
        );

        controlsRef.current.init(videoRef.current, player.current, {
          togglePlayOnClick: true,
        });

        setupListeners();
      };

      React.useEffect(() => {
        const wrapperObserver = new ResizeObserver(() => {
          // Not fullscreen, so we need to make sure that the video div
          // has the correct styles
          if (wrapperRef.current?.style.length === 0) {
            (wrapperRef.current?.children[0] as HTMLDivElement).style.width =
              '100%';

            if (height) {
              (wrapperRef.current?.children[0] as HTMLDivElement).style.height =
                height;
            }
          }
        });

        wrapperObserver.observe(wrapperRef.current!);

        return () => {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          wrapperRef.current && wrapperObserver.unobserve(wrapperRef.current);

          /** Cleanup the player and plugins */
          cleanup(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      React.useEffect(() => {
        sourceChanged();
        /* eslint-disable react-hooks/exhaustive-deps */
      }, [src]);

      React.useEffect(() => {
        setupListeners();
        /* eslint-disable react-hooks/exhaustive-deps */
      }, [onError, onEnded, onLoad]);

      React.useEffect(() => {
        setupSubtitles();
        /* eslint-disable react-hooks/exhaustive-deps */
      }, [subtitles]);

      React.useEffect(() => {
        pointsChanged();
        /* eslint-disable react-hooks/exhaustive-deps */
      }, [points]);

      React.useEffect(() => {
        setupHotkeys();
        /* eslint-disable react-hooks/exhaustive-deps */
      }, [hotkeys]);

      if (!license) {
        return <div>Must have a license for Accurate Player</div>;
      }

      return (
        <div
          style={{
            position: 'relative',
            width: '100%',
            ...(height && {
              height,
            }),
            backgroundColor: 'black',
          }}
        >
          <div ref={wrapperRef}>
            <video
              id={id}
              style={{
                width: '100%',
                ...(height && {
                  height,
                }),
              }}
              ref={videoRef}
              crossOrigin='anonymous'
              onClick={() => player.current?.api.togglePlay()}
            ></video>
            <apc-controls ref={controlsRef}></apc-controls>
          </div>
        </div>
      );
    },
  ),
  // React doesn't handle deep comparison of objects
  // So the options prop will force a re-render even if it's the same
  () => false,
);

Player.displayName = 'Player';
