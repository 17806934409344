(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("lodash-es"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "lodash-es"], factory);
	else if(typeof exports === 'object')
		exports["@pluto-tv/assemble-player"] = factory(require("react"), require("lodash-es"));
	else
		root["@pluto-tv/assemble-player"] = factory(root["react"], root["lodash-es"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__212__) => {
return 